<template>
  <div class="plan-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item>
          <i class="icon-home"></i>方案管理</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="search-box" @keyup.enter="handleSubmit()">
        <Row>
          <Col span="24">
          <h2 class="search-title border-bottom">按条件搜索</h2>
          </Col>
        </Row>
        <Form class="search-form" :label-width="85">
          <Row>
            <Col span="5">
            <Form-item label="上传日期：">
              <Date-picker v-model="time" @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
            </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
            <Form-item label="会员姓名：">
              <Input v-model="searchForm.memble_name" placeholder="请输入..."></Input>
            </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="顾客类型：" prop="member_type_Array">
                <Cascader :data="memberTypeData" v-model="member_type_Array"></Cascader>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="方案名称：">
                <Select  clearable v-model="searchForm.plan_name_id" placeholder="请选择">
                  <Option :value="v.id" v-for="v in plan_type_list" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="5">
              <Form-item label="省份：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
                <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                  <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="城市：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
                <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                  <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="门店：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
                <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                  <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <div class="tac">
            <Button type="primary" class="search-btn" @click="handleSubmit()">搜索</Button>
          </div>
        </Form>
      </div>
      <div class="filter-list-tpl">
        <Row>
          <Col span="20">
          <div style="display:inline-block;vertical-align:top;">请选择：</div>
          <Radio-group v-model="searchForm.status" @on-change="changeStatus">
            <Radio label="0">已生成方案</Radio>
            <Radio label="1">未生成会员名单</Radio>
          </Radio-group>
          </Col>
          <Col span="4">
            <a href="javascript:;" class="btn-allot" @click="distributeAll()">生成方案</a>
          </Col>
        </Row>
        <Table stripe :columns="searchForm.status == 0 ? columns : columns1" :data="planList" @on-selection-change="selectOne"></Table>
        <Row>
          <span class="records">共{{pageTotal}}条记录</span>
        </Row>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import planService from '@/services/planService';
export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {
			searchForm: {
				page: 1,
				size: 10,
				start_time: '',
				end_time: '',
				memble_name: '',
				pid: '',
				cid: '',
				sid: '',
				member_type_id: '',
				member_card_id: '',
				team: '',
				status: '0',
				plan_name_id: '',
			},
			distributeAllForm: {
				team_id: '',
				user_id: '',
				memble_name: '',
				pid: '',
				cid: '',
				sid: '',
				member_type_id: '',
				member_card_id: '',
				status: '',
			},
			distribution: {
				plan_ids: '',
				team_id: '',
				user_id: '',
			},
			time: [],
			gradeList: [],
			memberTypeData: [],
			member_type_Array: [],
			teamList: [],
			memberList: [],
			team_member: {},
			status_ids: [],
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '上传日期', key: 'complete_time', align: 'center' },
				{ title: '方案名称', key: 'plan_name', align: 'center' },
				{
					title: '会员姓名',
					key: 'member_name',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/plan/new/generate/all' +
											this.planList[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{ title: '顾客类型', key: 'new_member_type', align: 'center' },
				// { title: '状态', key: 'status_string', align: 'center' }
				{
					title: '操作',
					key: 'member_id',
					align: 'center',
					render: (h, params) => {
						return h('a', {
							attrs: {
								href: '#/base/plan/new/generate/' + params.row.member_id,
							},
							style: {
								'margin-right': 0,
							},
							class: 'icon_eye',
						});
					},
				},
			],
			columns1: [
				{
					title: '会员姓名',
					key: 'member_name',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.planList[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{ title: '顾客类型', key: 'new_member_type', align: 'center' },
				{
					title: '操作',
					key: 'member_id',
					align: 'center',
					render: (h, params) => {
						return h('a', {
							attrs: {
								href: '#/base/plan/new/generate/' + params.row.member_id,
							},
							style: {
								'margin-right': 0,
							},
							class: 'icon_edit',
						});
					},
				},
			],
			planList: [],
			pageTotal: 0,
			fpmodal: false,
			fpmodalAll: false,
			power: '',
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			pro_name: '',
			city_name: '',
			branch_name: '',
			plan_type_list: [],
		};
	},
	watch: {
		member_type_Array() {
			this.searchForm.member_type_id = this.member_type_Array[0];
			this.searchForm.member_card_id = this.member_type_Array[1];
		},
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getPlanList();
		planService.getPlanSelect().then((data) => {
			this.gradeList = data.grade;
			this.teamList = data.team;
			this.team_member = data.team_member;
			this.memberTypeData = data.member_type;
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
			this.plan_type_list = data.plan_type_list;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		getPlanList() {
			planService.getAutoPlanList(this.searchForm).then((data) => {
				this.planList = data.list;
				if (data.cur_page > data.page_size && data.row_size !== 0) {
					this.pageTotal = data.row_size + 1;
				} else {
					this.pageTotal = data.row_size;
				}
				this.del.plan_ids = '';
				this.distribution.plan_ids = '';
			});
		},
		handleSubmit() {
			this.searchForm.page = 1;
			this.getPlanList();
			this.distributeAllForm.memble_name = this.searchForm.memble_name;
			this.distributeAllForm.pid = this.searchForm.pid;
			this.distributeAllForm.cid = this.searchForm.cid;
			this.distributeAllForm.sid = this.searchForm.sid;
			this.distributeAllForm.member_type_id = this.searchForm.member_type_id;
			this.distributeAllForm.member_card_id = this.searchForm.member_card_id;
		},
		changeTime(date) {
			this.searchForm.start_time = date[0];
			this.searchForm.end_time = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getPlanList();
		},
		changeStatus() {
			this.distributeAllForm.status = this.searchForm.status;
			this.searchForm.page = 1;
			this.getPlanList();
		},
		selectOne(selection) {
			let arrId = [];
			let arrStatus = [];
			for (var i = 0; i < selection.length; i++) {
				arrId.push(selection[i].id);
				arrStatus.push(selection[i].status);
			}
			this.del.plan_ids = this.distribution.plan_ids = arrId.join(',');
			this.status_ids = arrStatus.join(',');
		},
		distribute() {
			// console.log(this.del.plan_ids)
			// console.log(this.distribution.plan_ids)
			if (this.distribution.plan_ids) {
				if (
					this.status_ids.indexOf('2') >= 0 ||
					this.status_ids.indexOf('3') >= 0 ||
					this.status_ids.indexOf('4') >= 0 ||
					this.status_ids.indexOf('5') >= 0 ||
					this.status_ids.indexOf('6') >= 0 ||
					this.status_ids.indexOf('7') >= 0
				) {
					this.$Message.warning('请选择未分配的方案');
				} else {
					this.fpmodal = true;
				}
			} else {
				this.$Message.warning('请先至少选择一个方案');
			}
		},
		distributeAll() {
			this.$router.push({ name: 'generateReport', params: { id: 'all' } });
		},
		changeTeam() {
			this.memberList = this.team_member[this.distribution.team_id];
		},
		okDistri() {
			if (!this.distribution.user_id) {
				this.$Message.warning('请进行具体分配');
			} else {
				planService.distribute(this.distribution).then(() => {
					this.getPlanList();
					this.fpmodal = false;
				});
			}
		},
		okDistriAll() {
			this.distributeAllForm.user_id = this.distribution.user_id;
			this.distributeAllForm.team_id = this.distribution.team_id;
			if (!this.distribution.user_id) {
				this.$Message.warning('请进行具体分配');
			} else {
				planService.distributeAll(this.distributeAllForm).then(() => {
					this.getPlanList();
					this.fpmodalAll = false;
				});
			}
		},
		cancel() {
			this.fpmodal = false;
			// this.distribution.plan_ids = ''
		},
		cancelAll() {
			this.fpmodalAll = false;
		},
		goFenpei(index) {
			this.distribution.plan_ids = this.planList[index].id;
			this.fpmodal = true;
		},
	},
};
</script>

<style lang="css" scoped>
.slotbody {
  padding-bottom: 12px;
}

.slotbody p {
  padding: 12px 0;
}
</style>
